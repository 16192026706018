<template>
  <base-material-card icon="mdi-clipboard-text" :title="projectName" class="px-5 py-3">
    <div class="project--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedPropertyType') }"
        >
          <label class="col-12 col-lg-3" for="selectedPropertyTypeField">
            <span>Tipe Properti</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedPropertyType"
              id="selectedPropertyTypeField"
              v-model="selectedPropertyType"
              :options="propertyTypes"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('selectedPropertyType') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('subsidized') }">
          <label class="col-12 col-lg-3" for="subsidizedField">
            <span>Status Subsidi</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="subsidized"
              id="subsidizedField"
              v-model="subsidized"
              :options="subsidyList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('subsidized') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('projectName') }">
          <label class="col-12 col-lg-3" for="projectNameField">
            <span>Nama Project</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="projectNameField"
              v-model="projectName"
              type="text"
              name="projectName"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('projectName') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('projectDesc') }">
          <label class="col-12 col-lg-3" for="projectDescField">
            <span>Deskripsi Project</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="projectDescField"
              v-model="projectDesc"
              rows="5"
              name="projectDesc"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('projectDesc') }}</span>
          </div>
        </div>
        <regional-section :isActive="isActive" ref="regionalSection" />
        <div class="form--group row" :class="{ 'has-error': validation.hasError('address') }">
          <label class="col-12 col-lg-3" for="addressField">
            <span>Alamat</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="addressField"
              v-model="address"
              rows="2"
              name="address"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('address') }}</span>
          </div>
        </div>
        <div class="form--group row">
          <label class="col-12 col-lg-3">
            <span>Harga</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-2">
            <cleave
              v-model="minPrice"
              :options="cleaveOptionCurrency"
              class="basic--input"
              name="minPrice"
              type="text"
              placeholder="Min"
            />
            <span class="val-error">{{ validation.firstError('minPrice') }}</span>
          </div>
          <div class="col-auto d-flex justify-center align-center">-</div>
          <div class="col-12 col-lg-2">
            <cleave
              v-model="maxPrice"
              :options="cleaveOptionCurrency"
              class="basic--input"
              name="maxPrice"
              type="text"
              placeholder="Max"
            />
            <span class="val-error">{{ validation.firstError('maxPrice') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('commission') }">
          <label class="col-12 col-lg-3" for="commissionField">
            <span>Komisi</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <cleave
              v-model="commission"
              :options="cleaveOptionDecimal"
              class="basic--input"
              name="commission"
              type="text"
              placeholder="Komisi"
            />
            <span class="val-error">{{ validation.firstError('commission') }}</span>
          </div>
        </div>

        <map-section :isActive="isActive" ref="mapSection" />
        <photo-section :isActive="isActive" ref="photoSection" />

        <div class="form--group row" :class="{ 'has-error': validation.hasError('developerName') }">
          <label class="col-12 col-lg-3" for="developerNameField">
            <span>Nama Developer</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="developerNameField"
              v-model="developerName"
              rows="2"
              name="developerName"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('developerName') }}</span>
          </div>
        </div>
        <developer-photo-section :isActive="isActive" ref="developerPhotoSection" />
        <div class="form--group row" :class="{ 'has-error': validation.hasError('youtubeUrl') }">
          <label class="col-12 col-lg-3" for="youtubeUrlField">{{
            $t('project.youtubeUrl')
          }}</label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="youtubeUrlField"
              v-model="youtubeUrl"
              rows="2"
              name="youtubeUrl"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('youtubeUrl') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('brochureUrl') }">
          <label class="col-12 col-lg-3" for="brochureUrlField">{{
            $t('project.brochureUrl')
          }}</label>
          <div class="col-12 col-lg-6">
            <v-textarea
              id="brochureUrlField"
              v-model="brochureUrl"
              rows="2"
              name="brochureUrl"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('brochureUrl') }}</span>
          </div>
        </div>
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedConstructionStatus') }"
        >
          <label class="col-12 col-lg-3" for="selectedConstructionStatusField"
            ><span>{{ $t('project.constructionStatus') }}</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedConstructionStatus"
              id="selectedConstructionStatusField"
              v-model="selectedConstructionStatus"
              :options="constructionStatuses"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('selectedConstructionStatus') }}</span>
          </div>
        </div>

        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('estimatedFinishYear') }"
        >
          <label class="col-12 col-lg-3" for="estimatedFinishYearField">{{
            $t('project.estFinishYear')
          }}</label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="estimatedFinishYearField"
              v-model="estimatedFinishYear"
              name="estimatedFinishYear"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('estimatedFinishYear') }}</span>
          </div>
        </div>
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedCertificate') }"
        >
          <label class="col-12 col-lg-3" for="selectedCertificateField">
            <span>Sertifikat</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedCertificate"
              id="selectedCertificateField"
              v-model="selectedCertificate"
              :options="certificates"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('selectedCertificate') }}</span>
          </div>
        </div>
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedWaterSource') }"
        >
          <label class="col-12 col-lg-3" for="selectedWaterSourceField"
            ><span>{{ $t('project.waterSource') }}</span
            ><span class="required">*</span></label
          >
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedWaterSource"
              id="selectedWaterSourceField"
              v-model="selectedWaterSource"
              :options="waterSources"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('selectedWaterSource') }}</span>
          </div>
        </div>
        <nearby-places-section />
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedProjectType') }"
        >
          <label class="col-12 col-lg-3" for="selectedProjectType">
            <span>Tipe Proyek</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedProjectType"
              id="selectedProjectTypeField"
              v-model="selectedProjectType"
              :options="projectTypes"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('selectedProjectType') }}</span>
          </div>
        </div>
        <contact-section
          ref="contactSection"
          v-if="selectedProjectType && selectedProjectType.id === 2"
        />
        <div class="form--group row">
          <label class="col-12 col-lg-3">Aktif?</label>
          <div class="col-12 col-lg-6">
            <v-btn
              type="button"
              color="success"
              :class="{ 'v-btn--disabled': !isActive }"
              @click="isActive = true"
            >
              {{ $t('general.yes') }}
            </v-btn>
            <v-btn
              type="button"
              color="error"
              :class="{ 'v-btn--disabled': isActive }"
              @click="isActive = false"
            >
              {{ $t('general.no') }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center justify-end">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('project.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
const MapSection = () => import('@/components/project-management/project/form/map/map-section.vue');
const PhotoSection = () => import('@/components/project-management/project/form/photo-section.vue');
const DeveloperPhotoSection = () =>
  import('@/components/project-management/project/form/developer-photo-section.vue');
const RegionalSection = () =>
  import('@/components/project-management/project/form/regional-section.vue');
const NearbyPlacesSection = () =>
  import('@/components/project-management/project/form/nearby-places/nearby-places-section.vue');
const ContactSection = () =>
  import('@/components/project-management/project/form/contact/contact-section.vue');

export default {
  name: 'project-form',
  mixins: [HelperMixin],
  components: {
    PageLoading,
    Multiselect,
    MapSection,
    PhotoSection,
    RegionalSection,
    DeveloperPhotoSection,
    NearbyPlacesSection,
    ContactSection,
  },
  computed: {
    ...mapState({
      formType: (state) => state.project.form.formType,
      projectTypes: (state) => state.project.form.projectTypes,
      propertyTypes: (state) => state.project.form.propertyTypes,
      certificates: (state) => state.project.form.certificates,
      waterSources: (state) => state.project.form.waterSources,
      constructionStatuses: (state) => state.project.form.constructionStatuses,
      cleaveOptionDecimal: (state) => state.global.cleaveOptionDecimal,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      cleaveOptionNumber: (state) => state.global.cleaveOptionNumber,
      subsidyList: (state) => state.project.form.subsidyList,
    }),
    loading: {
      get() {
        return this.$store.state.project.form.loading;
      },
      set(value) {
        this.$store.commit('project/form/SET_LOADING', value);
      },
    },
    projectName: {
      get() {
        return this.$store.state.project.form.projectName;
      },
      set(value) {
        this.$store.commit('project/form/SET_PROJECT_NAME', value);
      },
    },
    projectDesc: {
      get() {
        return this.$store.state.project.form.projectDesc;
      },
      set(value) {
        this.$store.commit('project/form/SET_PROJECT_DESC', value);
      },
    },
    address: {
      get() {
        return this.$store.state.project.form.address;
      },
      set(value) {
        this.$store.commit('project/form/SET_ADDRESS', value);
      },
    },
    commission: {
      get() {
        return this.$store.state.project.form.commission;
      },
      set(value) {
        this.$store.commit('project/form/SET_COMMISSION', value);
      },
    },
    minPrice: {
      get() {
        return this.$store.state.project.form.minPrice;
      },
      set(value) {
        this.$store.commit('project/form/SET_MIN_PRICE', value);
      },
    },
    maxPrice: {
      get() {
        return this.$store.state.project.form.maxPrice;
      },
      set(value) {
        this.$store.commit('project/form/SET_MAX_PRICE', value);
      },
    },
    developerName: {
      get() {
        return this.$store.state.project.form.developerName;
      },
      set(value) {
        this.$store.commit('project/form/SET_DEVELOPER_NAME', value);
      },
    },
    isActive: {
      get() {
        return this.$store.state.project.form.isActive;
      },
      set(value) {
        this.$store.commit('project/form/SET_IS_ACTIVE', value);
      },
    },
    youtubeUrl: {
      get() {
        return this.$store.state.project.form.youtubeUrl;
      },
      set(value) {
        this.$store.commit('project/form/SET_YOUTUBE_URL', value);
      },
    },
    brochureUrl: {
      get() {
        return this.$store.state.project.form.brochureUrl;
      },
      set(value) {
        this.$store.commit('project/form/SET_BROCHURE_URL', value);
      },
    },
    estimatedFinishYear: {
      get() {
        return this.$store.state.project.form.estimatedFinishYear;
      },
      set(value) {
        this.$store.commit('project/form/SET_ESTIMATED_FINISH_YEAR', value);
      },
    },
    selectedProjectType: {
      get() {
        return this.$store.state.project.form.selectedProjectType;
      },
      set(value) {
        this.$store.commit('project/form/SET_SELECTED_PROJECT_TYPE', value);
      },
    },
    selectedPropertyType: {
      get() {
        return this.$store.state.project.form.selectedPropertyType;
      },
      set(value) {
        this.$store.commit('project/form/SET_SELECTED_PROPERTY_TYPE', value);
      },
    },
    selectedCertificate: {
      get() {
        return this.$store.state.project.form.selectedCertificate;
      },
      set(value) {
        this.$store.commit('project/form/SET_SELECTED_CERTIFICATE', value);
      },
    },
    selectedWaterSource: {
      get() {
        return this.$store.state.project.form.selectedWaterSource;
      },
      set(value) {
        this.$store.commit('project/form/SET_SELECTED_WATER_SOURCE', value);
      },
    },
    selectedConstructionStatus: {
      get() {
        return this.$store.state.project.form.selectedConstructionStatus;
      },
      set(value) {
        this.$store.commit('project/form/SET_SELECTED_CONSTRUCTION_STATUS', value);
      },
    },
    subsidized: {
      get() {
        return this.$store.state.project.form.subsidized;
      },
      set(value) {
        this.$store.commit('project/form/SET_SUBSIDIZED', value);
      },
    },
  },
  validators: {
    selectedProjectType(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addProject.selectedProjectType.required'),
      );
    },
    selectedPropertyType(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addProject.selectedPropertyType.required'),
      );
    },
    subsidized(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addProject.subsidized.required'));
    },
    projectName(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addProject.projectName.required'),
      );
    },
    projectDesc(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.projectDesc.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    address(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.address.required'));
      } else {
        return Validator.value(true);
      }
    },
    minPrice(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.price.required'));
      } else {
        return Validator.value(true);
      }
    },
    maxPrice(value) {
      if (this.isActive) {
        return Validator.value(value).required(this.$i18n.t('errors.addProject.price.required'));
      } else {
        return Validator.value(true);
      }
    },
    commission(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.commission.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    developerName(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.developerName.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    selectedConstructionStatus(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.selectedConstructionStatus.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    selectedCertificate(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.selectedCertificate.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
    selectedWaterSource(value) {
      if (this.isActive) {
        return Validator.value(value).required(
          this.$i18n.t('errors.addProject.selectedWaterSource.required'),
        );
      } else {
        return Validator.value(true);
      }
    },
  },
  methods: {
    async validate() {
      let validationArray = [];
      validationArray.push(this.$refs.regionalSection.validate());
      validationArray.push(this.$refs.mapSection.validate());
      validationArray.push(this.$refs.photoSection.validate());
      validationArray.push(this.$refs.developerPhotoSection.validate());
      if (this.$refs.contactSection) {
        validationArray.push(this.$refs.contactSection.validate());
      }
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('project/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.publish.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
